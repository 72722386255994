<template>
  <div class="home">
    <div class="wx-h5" v-loading.fullscreen.lock="loading"></div>
  </div>
</template>

<script>
import wx from 'weixin-js-sdk'
export default {
  name: 'HomeView',
  data () {
    return {
      loading: true
    }
  },
  mounted () {
    if (this.getUrlCode().code) {
      wx.miniProgram.redirectTo({ url: `/pages/order_msg/order_msg?code=${this.getUrlCode().code}` })
    }
  },
  methods: {
    getUrlCode () {
      // 截取url中的code方法
      const url = location.search
      // eslint-disable-next-line no-new-object
      const theRequest = new Object()
      if (url.indexOf('?') !== -1) {
        const str = url.substr(1)
        const strs = str.split('&')
        for (let i = 0; i < strs.length; i++) {
          theRequest[strs[i].split('=')[0]] = strs[i].split('=')[1]
        }
      }
      return theRequest
    }
  }
}
</script>
<style>
  .home{
    width: 100%;
    height: 100vh;
  }
  .wx-h5{
    width: 100%;
    height: 100vh;
  }
</style>
